import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import {buildBreadcrumbsItem, handleInnerNavigation, getHeaderSize} from '../helpers'
import styles from '../scss/page.scss'

class InfluencerPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Influencer Program | Honest Paws®'
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'Here’s your chance to become an Honest Paws Influencer, giving you the ability to promote the most-trusted brand in Pet CBD.'
      },
      children: []
    }

    this.breadcrumbs = [
      buildBreadcrumbsItem('Influencer Program', this.props.location.href)
    ]

    this.imageInfluencer1 = this.props.data.imageInfluencer1.childImageSharp.fluid
    this.imageInfluencer2 = this.props.data.imageInfluencer2.childImageSharp.fluid
    this.imageInfluencer3 = this.props.data.imageInfluencer3.childImageSharp.fluid

    this.state = {
      headerHeight: 0
    }

  }

  componentDidMount() {
    this.setState({
      headerHeight: getHeaderSize().height
    })
  }

  render() {
    return (
      <Layout containerClassName={`page page-retailers`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden" location={this.props.location} >
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">Influencer Program</h1>
            </div>
          </div>
        </section>
        <section className="bg-white become-a-retailer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
                <p className="text-center font-weight-600 mt-2 mb-3 mt-md-4 mb-md-5  section-subtitle">
                  Here’s your chance to become an Honest Paws Influencer, giving you the ability to promote the most-trusted brand in Pet CBD.
                </p>
                <a href="#apply-form" className="btn btn-primary my-5" onClick={(e) => handleInnerNavigation(e, -this.state.headerHeight)}>Apply Now</a>
              </div>
              <div className="col-12 col-md-4 my-2">
                <Img fluid={this.imageInfluencer1} alt="Influecer Program 1" />
              </div>
              <div className="col-12 col-md-4 my-2">
                <Img fluid={this.imageInfluencer2} alt="Influecer Program 2" />
              </div>
              <div className="col-12 col-md-4 my-2">
                <Img fluid={this.imageInfluencer3} alt="Influecer Program 3" />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light signup-form">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1" id="form-container">
                <h2 id="apply-form" className="text-primary mb-0">You are almost there!</h2>
                <p className="mb-3 mt-md-4 mb-md-5 section-subtitle">
                  We just need some information from you and we will reach out to you.
                </p>
                <div>
                  <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScfXScxKz6qpx6xBjkAoaL3EkgXhMMSwVA7Z-gzKL6i3eSx8Q/viewform?embedded=true" width="700" height="520" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    )
  }
}

export default InfluencerPage

export const InfluencerPageQuery = graphql`
  query InfluencerPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    imageInfluencer1: file(relativePath: { eq: "landing-pages/influencer-program-1.jpg" }) {
      childImageSharp {
        fluid (maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageInfluencer2: file(relativePath: { eq: "landing-pages/influencer-program-2.jpg" }) {
      childImageSharp {
        fluid (maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageInfluencer3: file(relativePath: { eq: "landing-pages/influencer-program-3.jpg" }) {
      childImageSharp {
        fluid (maxWidth: 600, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`